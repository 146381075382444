import { Constants } from "@/helpers/constants";
import { useAuthStore } from "@/stores/auth.store";

/**
 * API call wrapper
 */
export class Api {
    /**
     * API base URL
     */
    private url = '';
    /**
     * authentication store (containing the token)
     */
    private auth = useAuthStore();
    /**
     * request queue. Holds pending requests when using [[getQueued]]
     */
    private queue: any[] = [];

    /**
     * Singleton instance of the API.
     * API is used out of Vue's context so we cannot use its injection mechanism
     */
    private static _instance: Api;
    static get instance(): Api {
        if (!Api._instance) {
            Api._instance = new Api();
        }
        return Api._instance;
    }

    /**
     * initialization method. Just set the base URL
     * @param url base API url
     */
    init(url: string) {
        this.url = url;
    }

    /**
     * perform a GET request using [[fetchWrapper]]
     */
    get(endpoint: string, json = true) {
        return this.fetchWrapper(endpoint, json, 'GET');
    }

    /**
     * perform a DELETE request using [[fetchWrapper]]
     */
    delete(endpoint: string, json = true) {
        return this.fetchWrapper(endpoint, json, 'DELETE');
    }

    /**
     * Fetch API endpoint with a specified method request and parse JSON result.
     * The auth token is appended to the headers if it is present in the [[auth]] store.
     * If the request returns a 401 (unauthorized) response, redirects to login
     * @param endpoint the API endpoint to send the request to
     * @param json parse the response as json (default true)
     * @returns the JSON parsed result of the request (or raw string if json==false)
     */
    fetchWrapper(endpoint: string, json = true, method:'GET'|'DELETE') {
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
                'app-name': Constants.APP_NAME,
            }
        };
        if (this.auth.idToken) {
            options.headers['Authorization'] = `Bearer ${this.auth.idToken}`;
        }
        return fetch(this.url + endpoint, options)
            .then(resp => {
                if (resp.status && resp.status == 401) { //unauthorized
                    console.log('Unauthorized API access =>probably unlogged');
                    this.auth.logout();
                    throw Error('401 unauthorized');
                }
                return json ? resp.json() : resp.text();
            });
    }
    /**
     * Queued version of [[get]]
     * The request is wrapped in a promise and its resolve handler is pushed to the [[queue]] array to be executed 
     * only when the previous request in the array is complete 
     * @param endpoint the API endpoint to send the request to
     * @returns the JSON parsed result of the request
     */
    getQueued(endpoint: string) {
        let resolve: any = null;
        const p = new Promise<any>((res) => {
            resolve = res;
        }).then(() => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': '',
                    'app-name': Constants.APP_NAME,
                }
            };
            if (this.auth.idToken) {
                options.headers['Authorization'] = `Bearer ${this.auth.idToken}`;
            }
            return fetch(this.url + endpoint, options)
                .then(resp => {
                    if (resp.status && resp.status == 401) { //unauthorized
                        console.log('Unauthorized API access =>probably unlogged');
                        window.location.href = '/login';
                        throw Error('401 unauthorized');
                    }
                    this.dequeue();
                    return resp.json();
                });
        });

        //resolve immediately if there is no pending request
        if (this.queue.length <= 0) {
            if (resolve) {
                resolve(null);
            }
        }
        this.queue.push(resolve);
        return p;
    }

    /**
     * Dequeue the first request of the queue and resolve the next one.
     */
    private dequeue() {
        this.queue.shift();
        if (this.queue.length > 0) {
            this.queue[0]();
        }
    }

    /**
     * post data to API endpoint. data is Jsonified and added ot the request body.
     * id token is added as Authorization header
     * @param endpoint URL to post data to
     * @param data data to post
     * @returns response
     */
    post(endpoint: string, data: any) {

        const options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
                'app-name': Constants.APP_NAME,
            }
        };
        if (this.auth.idToken) {
            options.headers['Authorization'] = `Bearer ${this.auth.idToken}`;
        }

        return fetch(this.url + endpoint, options);
    }
}