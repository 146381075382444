import { Allocation, AllocationItem, Allocations, IAllocationService } from './interfaces';
import {Api} from './api';
import {toISOLocal} from '@/helpers/date';

/**
 * @implements IAllocationService
 */
export class AllocationService implements IAllocationService {
    
    /**
     * @implements IAllocationService.getWeeksFromDate
     */
    async getWeeksFromDate(personnelId: number, date:Date, nbWeeks = 1): Promise<Allocations> {
        
        return Api.instance
            .get(`allocations/WeeksFromDate/${personnelId}/${date.toISOString()}/${nbWeeks}/true`)
            .then((x:Allocation) => this.mapAllocations(x));

    }
    
    /**
     * toISOLocal is necessary because we use the time here
     * @implements IAllocationService.getDetails
     */
    async getDetails(personnelId: number, date: Date): Promise<(AllocationItem[])[]> {
        return Api.instance
            .get(`allocations/GetDetails/${personnelId}/${toISOLocal(date)}`)
            .then((x:(AllocationItem[])[]) => {
                x.forEach(y => y.forEach(z => this.mapAllocationItems(z)));
                return x;
            });
    }
    
    /**
     * toISOLocal is necessary because we use the time here
     * @implements IAllocationService.getDetailsBefore
     */
    async getDetailsBefore(lineId: number, shiftOccurenceId: number, start: Date, end: Date): Promise<(AllocationItem[])> {
        return Api.instance
            .get(`allocations/GetPlanningDetailBefore/${lineId}/${shiftOccurenceId}/${toISOLocal(start)}/${toISOLocal(end)}`)
            .then((x:AllocationItem[]) => {
                x.forEach(y => this.mapAllocationItems(y));
                return x;
            });
    }
    
    /**
     * toISOLocal is necessary because we use the time here
     * @implements IAllocationService.getDetailsAfter
     */
    async getDetailsAfter(lineId: number, shiftOccurenceId: number, start: Date, end: Date): Promise<(AllocationItem[])> {
        return Api.instance
            .get(`allocations/GetPlanningDetailAfter/${lineId}/${shiftOccurenceId}/${toISOLocal(start)}/${toISOLocal(end)}`)
            .then((x:AllocationItem[]) => {
                x.forEach(y => this.mapAllocationItems(y));
                return x;
            });
    }

    //TODO GMA need a better way to map these...
    mapAllocations(o: any): Allocations {
        o.weeks.forEach((w:any) => {
            w.start = new Date(w.start);
            w.end = new Date(w.end);
            w.days.forEach((d:any) => {
                d.day.dateTime = new Date(d.day.dateTime);
                d.allocations.forEach((a:any) => {
                    a.start = new Date(a.start);
                    a.end = new Date(a.end);
                });
            });
        });
        return o;
    }
    
    mapAllocationItems(o: any): AllocationItem {
        o.start = new Date(o.start);
        o.end = new Date(o.end);
        return o;
    }
}